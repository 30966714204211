<template>
  <div class="g-32">
    <div class="totals row full-width">
      <div class="numbers g-8 no-flex" style="padding-bottom: 8px;">
        <Numbers :unit="unit" :restparti="restparti"
                 :min_volume_qty="min_volume_qty"
                 :status="status"
                 :unit_per_box="unit_per_box" :volume-is-active="volumeIsActive" :activePrice="activePrice"
                 :totalArea="totalArea"/>
      </div>
      <div class="checks g-8" style="align-items: flex-end; max-width: 222px; margin-left: auto; text-align: right;">
        <ShowroomCheck v-if="!['Restparti','Lagerförsäljning','Offertvara'].includes(status) && exists_in_showroom"/>
        <UnitPerBoxCheck :unit_per_box="unit_per_box" :unit="unit"
                         v-if="!['Restparti'].includes(status) && unit === 'm2'"/>
        <DeliveryTimeCheck :delivery_time_html="delivery_time_html"/>
        <NoticeAboutStCheck v-if="niche_id === 'Kakel och Klinker' && unit === 'st'"/>
      </div>
    </div>

    <div class="g-16 the-same-gap">
      <div class="add-to-cart-block row full-width g-32" v-if="!['Restparti','Offertvara'].includes(status)">
        <div class="row g-8 the-same-gap">
          <div class="row">
            <Button type="minus" @click="minus"/>
            <Input width="32px" type="number" v-model="count"/>
            <Button type="plus" @click="plus"/>
          </div>
          <Text class="unit" tag="strong">{{ unit === 'm2' ? 'box' : unit }}</Text>
        </div>

        <div class="row no-flex g-4">
          <template v-if="niche_id==='Kakel och Klinker'">
            <Checkbox v-model="spill" id="spill" :text="'+10% '+ $t('product.extra_box')"/>
            <Help>{{ $t('product.extra_box_text') }}</Help>
          </template>
        </div>
        <div style="margin-left: auto;">
          <Text style="width: fit-content; margin-left: auto;" tag="strong">
            <ProductPrice class="reset total" :light-unit="true" :price="totalPrice"/>
          </Text>
        </div>
      </div>

      <div class="actions full-width g-16 the-same-gap">
        <div v-if="status!=='Offertvara'">
          <Button class="full-width tall coarse uppercase"
                  @click="store.addItemToCart({id,count,source:store.sources.product_page_add_to_cart_button}); $emit('cartAdded')"
                  type="black">
            <CartIcon width="18px" height="18px" stroke="white"/> &nbsp;
            {{ $t('cart.add_to_cart') }}
          </Button>
        </div>
        <div class="row full-width g-16 the-same-gap">
          <Button @click="addSample" v-if="unit!=='st'  && sample_is_available && sample_price && status!=='Offertvara'"
                  :sub_text="sample_delivery_time"
                  class="full-width tall"
                  :help="$device.isDesktop ? $t('product.about_sample'): null"
                  type="bronze">
            {{ $t('product.sample') }} -&nbsp;<ProductPrice :digits="0" :price='sample_price'/>
          </Button>
          <Button @click="addProductToQuotation" v-if="status!=='Restparti'" class="full-width tall"
                  :text="$t('quotation.company-quotation')"
                  type="bronze"/>
        </div>
      </div>

      <KlarnaParts v-if="status!=='Offertvara'" :total-price="totalPrice"/>
    </div>

    <div class="mobile-sticky-buy" v-if="$device.isMobile" :class="{active:activeSticky}">
      <div class="row full-width g-16 the-same-gap">
        <Button v-if="status!=='Offertvara'" class="full-width tall coarse uppercase"
                @click="store.addItemToCart({id,count,sample:0,source:store.sources.product_page_add_to_cart_fixed_button}); $emit('cartAdded')"
                type="black">
          <CartIcon width="18px" height="18px" stroke="white"/> &nbsp;
          {{ $t('cart.add_to_cart') }}
        </Button>
        <Button @click="addSample" v-if="unit!=='st' && sample_is_available && sample_price && status!=='Offertvara'"
                :sub_text="sample_delivery_time"
                class="full-width tall"
                :help="$device.isDesktop ? $t('product.about_sample'): null"
                type="bronze">
          {{ $t('product.sample') }} -&nbsp;<ProductPrice :digits="0" :price='sample_price'/>
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import CheckLabel from "~/components/atoms/CheckLabel.vue";
import Button from "~/components/atoms/Button.vue";
import Input from "~/components/atoms/Input.vue";
import Text from "~/components/atoms/Text.vue";
import Checkbox from "~/components/atoms/Checkbox.vue";
import Price from "~/components/atoms/Price.vue";
import ProductPrice from "~/components/molecules/ProductPrice.vue";
import ProductUnit from "~/components/molecules/ProductUnit.vue";
import PointLabel from "~/components/atoms/PointLabel.vue";
import KlarnaParts from "~/components/organisms/KlarnaParts.vue";
import DeliveryTimeCheck from "~/components/molecules/ProductPage/Checks/DeliveryTimeCheck.vue";
import ShowroomCheck from "~/components/molecules/ProductPage/Checks/ShowroomCheck.vue";
import UnitPerBoxCheck from "~/components/molecules/ProductPage/Checks/UnitPerBoxCheck.vue";
import NoticeAboutStCheck from "~/components/molecules/ProductPage/Checks/NoticeAboutStCheck.vue";
import Numbers from "~/components/molecules/ProductPage/Numbers.vue";
import {useCartStore} from "~/store/cartStore.js";
import {useQuotationStore} from "~/store/quotationStore.js";
import Modal from "~/components/molecules/Modal.vue";
import SampleForm from "~/components/organisms/SampleForm.vue";
import Help from "~/components/molecules/Help.vue";
import MarketCartIcon from "~/components/icons/MarketCartIcon.vue";
import CartIcon from "~/components/icons/CartIcon.vue";

export default {
  props: ['unit', 'delivery_time_html', 'sample_is_available', 'image', 'name', 'sku', 'url', 'max_cart_qty', 'min_volume_to_buy', 'cart_multiplicity',
    'unit_per_box', 'min_volume_qty', 'sample_price', 'sample_delivery_time', 'status', 'id', 'supplementary', 'restparti',
    'pricePerBox', 'standard_price', 'volume_price', 'exists_in_showroom', 'niche_id', 'sample_modal_is_available'],
  components: {
    CartIcon,
    MarketCartIcon,
    Help,
    SampleForm,
    Modal,
    Numbers,
    NoticeAboutStCheck,
    UnitPerBoxCheck,
    ShowroomCheck,
    DeliveryTimeCheck,
    KlarnaParts,
    PointLabel, ProductUnit, ProductPrice, Price, Checkbox, Text, Input, Button, CheckLabel
  },
  data() {
    return {
      count: this.min_volume_to_buy ? this.min_volume_to_buy : 1,
      store: useCartStore(),
      quotationStore: useQuotationStore(),
      spill: false,
      actions: null,
      activeSticky: false
    }
  },
  mounted() {
    if (this.min_volume_to_buy) {
      this.count = this.min_volume_to_buy
    }

    window.addEventListener('scroll', this.onScroll)

    this.actions = document.querySelector('.actions')
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    onScroll(e) {
      const elem = this.actions
      if (elem) {
        const top = elem.getBoundingClientRect().top

        if (elem) {
          if (top < -20) {
            this.activeSticky = true
          } else {
            this.activeSticky = false
          }
        }
      }
    },
    addSample() {
      if (this.sample_modal_is_available) {
        this.$emit('show-sample-form')
      } else {
        this.store.addItemToCart({
          id: this.id,
          count: 1,
          sample: 1,
          source: this.store.sources.product_page_sample_button
        })
      }
    },
    addProductToQuotation() {
      this.quotationStore.addProduct(this.image, this.name, this.sku, this.unit, this.unit_per_box, this.url)

      this.quotationStore.openQuotation()
    },
    minus() {
      this.count = parseInt(this.count)

      if (this.cart_multiplicity) {
        if (this.count > this.cart_multiplicity) {
          this.count -= this.cart_multiplicity
        }
      } else if (this.count > 1) {
        this.count--
      }
    },
    plus() {
      this.count = parseInt(this.count)
      if (this.cart_multiplicity) {
        this.count += this.cart_multiplicity
      } else {
        this.count++
      }
    }
  },
  watch: {
    spill: function (val) {
      if (val) {
        this.count = Math.ceil(this.count * 1.1)
      } else {
        this.count = Math.floor(this.count / 1.1)
      }
    },
    count: function () {
      if (this.max_cart_qty > 1 && this.count > this.max_cart_qty) {
        this.count = this.max_cart_qty
      }

      if (this.min_volume_to_buy && this.count < this.min_volume_to_buy) {
        this.count = this.min_volume_to_buy
      }

      if (this.cart_multiplicity && this.count % this.cart_multiplicity !== 0) {
        this.count = Math.ceil(this.count / this.cart_multiplicity) * this.cart_multiplicity
      }
    }
  },
  computed: {
    volumeIsActive: function () {
      return this.totalArea >= this.min_volume_qty;
    },
    activePrice: function () {
      return this.volumeIsActive ? this.volume_price : this.standard_price;
    },
    totalPrice: function () {
      return this.activePrice * this.count * (this.unit === 'st' ? 1 : this.unit_per_box);
    },
    totalArea: function () {
      if (this.unit === 'st') {
        return this.count;
      } else {
        return Math.round(this.count * this.unit_per_box * 100) / 100;
      }
    },
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables.scss";

* {
  font-size: 12px;
}

.unit {
  font-size: 14px;
}

.totals {
  color: $light-black;
}

.total {
  font-size: 13px !important;
}

.mobile-sticky-buy {
  position: fixed;
  bottom: -74px;
  z-index: 999;
  left: 0;
  right: 0;
  background: white;
  padding: 12px 16px;
  transition: all .3s ease-in-out;

  &.active {
    bottom: 0;
  }
}

.numbers:deep(.volume-is-active) {
  width: 82px;
  height: 23px;
  display: inline-block;
  font-size: 13px;
  right: -150%;
  padding: 2px 5px;
  margin-left: 10px;
  position: absolute;
  z-index: 9;
  top: -5px;
}

.add-to-cart-block:deep(input) {
  font-size: 14px;
}
</style>
